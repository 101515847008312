<template>
  <div class="testEquipment dataM">
    <el-table 
      :data="tableList" 
      header-cell-class-name="tableHeader" 
      style="width: 100%" 
      @selection-change="handleSelectionChange"
      @row-dblclick="handleEdit"
    >
      <el-table-column type="selection" width="55"/>
      <el-table-column label="序号" type="index" width="100">
        <template slot-scope="scope">
          <span>{{((page-1)*10+scope.$index+1) < 10 ? `0${(page-1)*10+scope.$index+1}` : (page-1)*10+scope.$index+1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="partCode" label="状态">
        <template slot="header">
          <el-dropdown>
            <span class="el-dropdown-link">
              状态<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="changeStatus(0)">全部</el-dropdown-item>
              <el-dropdown-item @click.native="changeStatus(1)">正常</el-dropdown-item>
              <el-dropdown-item @click.native="changeStatus(2)">故障</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.equipmentStatus == 1"  class="greenStatus">正常</span>
          <span  class="redStatus" v-else>故障</span>
        </template>
      </el-table-column>
      <el-table-column prop="equipmentNo" label="设备编号"/>
      <el-table-column prop="equipmentName" label="设备名称"/>
      <el-table-column prop="equipmentParameters" label="设备参数"/>
      <!-- <el-table-column prop="category" label="组别"/> -->
      <el-table-column prop="workshiftSchemeName" label="日历方案"/>
      <el-table-column prop="maintenancePlan" label="保养计划"/>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <div class="operation">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <img src="@/assets/images/dataManage/edit.png" style="margin-right:10px" @click="handleEdit(scope.row)"/>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
            </el-tooltip>
            <span style="margin:10px">|</span>
            <span style="color: #2DEB79;cursor:pointer" @click="seePlan(scope.row)">保养计划</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <planDialog :dialogVisible.sync="dialogVisible" :planData.sync="planData"/>
    <testEquipmentDialog :createVisible.sync="createVisible" :currentData="currentData" @handleSearch="handleSearch"/>
  </div>
</template>

<script>
const planDialog = () => import('../dialog/planDialog.vue')
const testEquipmentDialog = () => import('../dialog/testEquipmentDialog.vue')
export default {
  name:"testEquipment",
  components:{
    planDialog,
    testEquipmentDialog
  },
  props:{
    tableList:{
      require:true,
      default:[]
    },
    statusType:{
      require:true
    },
    page:{
      default:1
    }
  },
  data(){
    return{
      dialogVisible:false,
      planData:{},
      createVisible:false,
      currentData:{}
    }
  },
  methods:{
    handleSelectionChange(val){
      let multipleSelection = val.map(row => row.id);
      this.$emit('update:multipleSelection',multipleSelection);
    },
    comfirm(row){
      this.$emit('comfirm',row)
    },
    seePlan(row){
      console.log('row',row)
      this.planData = row
      this.dialogVisible = true
    },
    changeStatus(val){
      this.$emit('update:statusType',val)
    },
    handleEdit(row){
      this.currentData = row
      this.createVisible = true
    },
    handleSearch(){
      this.$emit('handleSearch')
    }
  }
}
</script>

<style lang="scss">
.testEquipment{
  .operation{
    font-size: 16px;
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      margin-left: 10px;
    }
  }
  img{
    margin: 0 15px;
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
}
</style>